import NextVideoLink from '@lyra/web/components/common/NextVideoLink'
import useSelectedTab from '@lyra/web/hooks/useSelectedTab'
import { TabDetails, TabId } from '@lyra/web/utils/tabs'

const NavDesktopHeaderTabLink = ({ tab }: { tab: TabDetails }) => {
  const selectedTab = useSelectedTab()

  return (
    <NextVideoLink
      key={tab.id}
      href={tab.pages[0].path}
      label={tab.name}
      isActive={selectedTab && selectedTab.id === tab.id}
      color={tab.id === TabId.DRV ? 'cta' : undefined}
      size="md"
    />
  )
}

export default NavDesktopHeaderTabLink
