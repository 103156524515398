import { IncidentResponseSchema } from '@lyra/core/api/types/public.get_live_incidents'
import BodyText from '@lyra/core/components/BodyText'
import Link from '@lyra/core/components/Link'
import Responsive from '@lyra/core/components/Responsive'
import isExternalURL from '@lyra/core/utils/isExternalURL'
import NextLink from '@lyra/web/components/common/NextLink'
import { SITE_WIDE_NOTICE_HEIGHT } from '@lyra/web/constants/layout'
import useLiveIncidents from '@lyra/web/hooks/useLiveIncidents'
import useSiteWideAnnouncements from '@lyra/web/hooks/useSiteWideAnnouncements'
import { useMemo } from 'react'
import Marquee from 'react-fast-marquee'
import { isServer, useTheme, XStack } from 'tamagui'

export default function NavBanner() {
  const { incidents, dismissIncident, isLoadingIncidents } = useLiveIncidents()
  const { activeAnnouncement, dismissAnnouncement, isLoadingAnnouncements } =
    useSiteWideAnnouncements()
  const theme = useTheme()

  const highestSeverityIncident: IncidentResponseSchema = useMemo(
    () => incidents?.sort((a) => (a.severity === 'high' ? -1 : a.severity === 'medium' ? 0 : 1))[0],
    [incidents]
  )

  if (isServer || isLoadingIncidents || isLoadingAnnouncements) {
    return null
  }

  const message = highestSeverityIncident
    ? highestSeverityIncident.message
    : activeAnnouncement?.message
  const isIncident = !!highestSeverityIncident
  const canDismiss = isIncident
    ? highestSeverityIncident.severity !== 'high'
    : activeAnnouncement?.isDismissible
  const href = !isIncident ? activeAnnouncement?.href : undefined

  if (!message) {
    return null
  }

  const background = isIncident
    ? highestSeverityIncident.severity === 'low'
      ? '$translucentBg'
      : '$amberBg'
    : `linear-gradient(90deg, ${theme.red_9?.get()}, ${theme.amber_9?.get()})`

  const messageContent = (
    <>
      {message}{' '}
      {href ? (
        isExternalURL(href) ? (
          <Link color="inverted" label="Learn more" href={href} target="_blank" />
        ) : (
          <NextLink color="inverted" label="Learn more" href={href as `/${string}`} />
        )
      ) : null}
    </>
  )

  return (
    <Responsive
      mobile={
        <XStack
          width="100%"
          paddingVertical="$1.5"
          minHeight={SITE_WIDE_NOTICE_HEIGHT}
          background={background}
        >
          <Marquee gradient={false} speed={40} pauseOnHover>
            <BodyText color={isIncident ? undefined : 'inverted'} textAlign="center">
              {messageContent}
            </BodyText>
          </Marquee>
          {canDismiss && (
            <Link
              color={isIncident ? undefined : 'inverted'}
              size="md"
              label="Dismiss"
              paddingHorizontal="$2"
              onPress={() =>
                isIncident
                  ? dismissIncident(highestSeverityIncident)
                  : activeAnnouncement && dismissAnnouncement(activeAnnouncement.id)
              }
            />
          )}
        </XStack>
      }
      desktop={
        <XStack
          width="100%"
          paddingVertical="$1.5"
          alignItems="center"
          justifyContent="space-between"
          minHeight={SITE_WIDE_NOTICE_HEIGHT}
          background={background}
        >
          <XStack flex={1} justifyContent="center">
            <BodyText color={isIncident ? undefined : 'inverted'} textAlign="center">
              {messageContent}
            </BodyText>
          </XStack>
          {canDismiss && (
            <Link
              color={isIncident ? undefined : 'inverted'}
              size="md"
              label="Dismiss"
              paddingHorizontal="$2"
              onPress={() =>
                isIncident
                  ? dismissIncident(highestSeverityIncident)
                  : activeAnnouncement && dismissAnnouncement(activeAnnouncement.id)
              }
            />
          )}
        </XStack>
      }
    />
  )
}
