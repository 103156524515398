'use client'
import {
  Notification,
  NOTIFICATIONS_DEFAULT_LAST_SEEN_TIMESTAMP,
  PartialNotification,
} from '@lyra/web/constants/notifications'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import NotificationIcon from '../components/common/NotificationIcon'
import { User } from '../constants/user'
import useAuth from '../hooks/useAuth'
import useOrderbookTimestamp from '../hooks/useOrderbookTimestamp'
import useToast from '../hooks/useToast'
import emptyFunction from '../utils/emptyFunction'
import getAuthHeaders from '../utils/getAuthHeaders'
import {
  fetchLatestNotifications,
  getIsNotificationSeen,
  getNotificationDescription,
  getNotificationIsReverted,
  getNotificationTitle,
} from '../utils/notifications'

type Props = {
  children?: React.ReactNode
}

export type NotificationContext = {
  notifications: Notification[]
  updateSeenNotifications: () => Promise<void>
  isLoading: boolean
  error: Error | undefined
}

export const NotificationContext = React.createContext<NotificationContext>({
  notifications: [],
  updateSeenNotifications: emptyFunction as any,
  isLoading: true,
  error: undefined,
})

const POLL_INTERVAL = 120_000 // 30s - bumped to 2min for token launch
const EMPTY_NOTIFICATIONS: PartialNotification[] = []

const fetcher = async (user: User) => {
  return await fetchLatestNotifications(user.address, getAuthHeaders(user))
}

export default function NotificationProvider({ children }: Props) {
  const { user, isAuthenticated } = useAuth()
  const { createToast } = useToast()
  const { getTimestamp } = useOrderbookTimestamp()

  const {
    data: rawNotifications = EMPTY_NOTIFICATIONS,
    isLoading,
    error,
  } = useSWR(user ? ['Notifications', user] : null, ([_, user]) => (user ? fetcher(user) : []), {
    refreshInterval: POLL_INTERVAL,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const [lastSeenTimestamp, setLastSeenTimestamp] = useState(
    Math.max(
      user?.lastSeenNotificationsTimestamp ?? NOTIFICATIONS_DEFAULT_LAST_SEEN_TIMESTAMP,
      NOTIFICATIONS_DEFAULT_LAST_SEEN_TIMESTAMP
    )
  )

  useEffect(() => {
    // sync lastSeen db with client, important for user authenticating
    if (
      user?.lastSeenNotificationsTimestamp &&
      // only sync if lastSeen in db is greater than client
      user.lastSeenNotificationsTimestamp > lastSeenTimestamp
    ) {
      setLastSeenTimestamp(user.lastSeenNotificationsTimestamp)
    }
  }, [user?.lastSeenNotificationsTimestamp, lastSeenTimestamp])

  const [lastAlertedTimestamp, setLastAlertedTimestamp] = useState(Date.now())

  const notifications: Notification[] = useMemo(() => {
    return rawNotifications.map((notif) => ({
      ...notif,
      isSeen: getIsNotificationSeen(notif, lastSeenTimestamp),
    }))
  }, [rawNotifications, lastSeenTimestamp])

  // update all seen notifications as seen
  const updateSeenNotifications = useCallback(async (): Promise<void> => {
    if (isAuthenticated) {
      console.debug('seen notifications', getTimestamp())
      // optimistically set latest timestamp to mark notifications as seen
      setLastSeenTimestamp(getTimestamp())
      // async persist latest timestamp
      await fetch('/api/auth/notifications/seen', { method: 'POST' })
    }
  }, [isAuthenticated, getTimestamp])

  /** LISTENERS **/

  // alert new notifications with toasts
  useEffect(() => {
    const newNotifications = notifications.filter(
      (notif) => notif.timestamp > lastAlertedTimestamp && !notif.isSeen
    )
    setLastAlertedTimestamp(Date.now())

    console.debug('notifications', { notifications, newNotifications })

    for (const notification of newNotifications) {
      createToast({
        title: getNotificationTitle(notification),
        description: getNotificationDescription(notification),
        icon: <NotificationIcon notification={notification} />,
        color: getNotificationIsReverted(notification) ? 'red' : 'primary',
      })
    }
    // IMPORTANT!! only listen to new notifications here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, createToast])

  const value = useMemo(() => {
    return {
      notifications,
      updateSeenNotifications,
      isLoading,
      error,
    }
  }, [notifications, updateSeenNotifications, isLoading, error])

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}
