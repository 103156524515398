import { useCallback, useMemo } from 'react'

import { Auth } from '../constants/auth'
import { EMPTY_USER_SETTINGS, UserSettings } from '../utils/userSettings'
import useLocalStorage from './local_storage/useLocalStorage'
import useAuth from './useAuth'

const LOCAL_STORAGE_USER_SETTINGS_KEY = 'derive.userSettings'

const setSettings = async (key: keyof UserSettings, value: UserSettings[keyof UserSettings]) => {
  const res = await fetch('/api/user-settings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ key, value }),
  })

  if (!res.ok) {
    console.error('Failed to update user settings')
  }
}

export default function useUserSettings() {
  const { user, mutate: mutateUser } = useAuth()

  const [storedSettings, setStoredSettings] = useLocalStorage(LOCAL_STORAGE_USER_SETTINGS_KEY)

  const localSettings = useMemo(() => {
    return storedSettings ? JSON.parse(storedSettings) : EMPTY_USER_SETTINGS
  }, [storedSettings])

  const userSettings: Required<UserSettings> = useMemo(() => {
    return {
      ...EMPTY_USER_SETTINGS,
      ...localSettings,
      ...user?.userSettings,
    }
  }, [localSettings, user?.userSettings])

  const updateSettings = useCallback(
    async (key: keyof UserSettings, value: UserSettings[keyof UserSettings]) => {
      if (!user) {
        const newSettings = {
          ...localSettings,
          [key]: value,
        }
        setStoredSettings(JSON.stringify(newSettings))
        return
      }

      await mutateUser(
        (data: Auth | undefined) =>
          data?.user
            ? {
                ...data,
                user: {
                  ...data.user,
                  userSettings: {
                    ...(data.user.userSettings ?? EMPTY_USER_SETTINGS),
                    [key]: value,
                  },
                } as typeof data.user,
              }
            : data,
        false
      )

      try {
        await setSettings(key, value)
        await mutateUser()
      } catch (e) {
        await mutateUser()
        console.error('Failed to update user settings:', e)
      }
    },
    [user, mutateUser, localSettings, setStoredSettings]
  )

  return useMemo(
    () => ({
      userSettings: userSettings,
      updateSettings,
    }),
    [userSettings, updateSettings]
  )
}
