import { useMemo } from 'react'

import { getTabs, TabDetails, TabId } from '../utils/tabs'
import useUserSettings from './useUserSettings'

const useTabs = (): Record<TabId, TabDetails> => {
  const { userSettings } = useUserSettings()

  return useMemo(() => {
    return getTabs({
      defaultOptionsMarket: userSettings.lastOptionsMarket,
      defaultPerpsMarket: userSettings.lastPerpsMarket,
      defaultSpotCollateral: userSettings.lastSpotCollateral,
    })
  }, [userSettings])
}

export default useTabs
